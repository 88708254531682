import request from '../utils/request';
import qs from "qs";


export const getFindOperationManual = param => {
    return request.get('/api/notice/findOperationManual', qs.stringify(param))
};

// export const getOrdersMakeUp= param => {
//     return request.post('/api/orders/makeUp', qs.stringify(param))
// };
// export const downloadOrders = param => {
//     return request.post('/api/orders/downloadOrders', qs.stringify(param),{
//         responseType: 'blob'
//     })
// };